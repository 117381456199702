import { render, staticRenderFns } from "./wechatRobotSet.vue?vue&type=template&id=e5dce60c&scoped=true&"
import script from "./wechatRobotSet.vue?vue&type=script&lang=js&"
export * from "./wechatRobotSet.vue?vue&type=script&lang=js&"
import style0 from "./wechatRobotSet.vue?vue&type=style&index=0&id=e5dce60c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5dce60c",
  null
  
)

export default component.exports