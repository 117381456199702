<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="wechatRobot-container common-padding">
        <div class="title"><span class="title-text" style="">微信提醒设置</span> / 设置</div>
        <div class="wechatRobot-main">
            <el-form ref="form" :model="ruleForm" :rules="rules" label-width="260px" style="width: 600px;margin: 32px 0">
                <el-form-item label="群名称：" prop="groupName">
                    <el-input class="common-form-input_100" v-model="ruleForm.groupName" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="群类型：">
                    <el-select class="common-form-input_100" v-model="ruleForm.groupType" placeholder="请选择群类型">
                        <el-option label="对接" :value="0"></el-option>
                        <el-option label="客服" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="登录异常是否发送：">
                    <el-switch v-model="ruleForm.login" :value="1"></el-switch>
                </el-form-item>
                <el-form-item label="客服排班是否发送：">
                    <el-switch v-model="ruleForm.scheduling"></el-switch>
                </el-form-item>
                <el-form-item label="公告是否发送：">
                    <el-switch v-model="ruleForm.isAnnouncement"></el-switch>
                </el-form-item>
                <el-form-item label="提醒预警类型：">
                    <el-radio-group v-model="ruleForm.isAssessment">
                        <el-radio label="超时预警"></el-radio>
                        <el-radio label="子账号均值"></el-radio>
                    </el-radio-group>
                    <el-form-item v-if="ruleForm.isAssessment == '子账号均值'" :rules="{required: true, message: '请选择时间', trigger: 'change'}" style="border-left: 4px solid #1890ff" label-width="120px" label="发送时间：">
                        <el-select class="common-screen-input_85common-form-input_100" v-model="ruleForm.isAssessmentTime" placeholder="请选择时间">
                            <el-option v-for="(item, index) in 59" :key="index" :label="index < 10 ? '0' + index : index" :value="index < 10 ? '0' + index : index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form-item>
                <el-form-item label="日报发送时间：">
                    <div v-for="(item, index) in ruleForm.daily" :key="index + '0'">
                        <el-time-picker class="common-screen-input_85" value-format="HH:mm:ss" v-model="ruleForm.daily[index]" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" placeholder="任意时间点"></el-time-picker>
                        <i class="el-icon-delete" v-if="ruleForm.daily.length != 1" @click="() => { deleteDaily(index) }"></i>
                        <i class="el-icon-plus" v-if="index == ruleForm.daily.length - 1" @click="() => { addDaily() }"></i>
                    </div>
                    
                </el-form-item>
                <el-form-item label="服务均值发送时间：">
                    <el-time-picker class="common-form-input_100" value-format="HH:mm:ss" v-model="ruleForm.assessmentTime" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" placeholder="任意时间点"></el-time-picker>
                </el-form-item>
                <el-form-item label="销售机会发送时间：">
                    <el-time-picker class="common-form-input_100" value-format="HH:mm:ss" v-model="ruleForm.chanceTime" :picker-options="{ selectableRange: '00:00:00 - 23:59:59' }" placeholder="任意时间点"></el-time-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="grey-line"></div>
        <div class="wechatRobot-bottom">
            <el-button class="common-screen-btn" type="primary"  @click=" () => { setWechat() }">确认</el-button>
          <el-button class="common-screen-btn" plain @click="() => { $router.push('/wechat/wechat-robot') }" >取消</el-button>
        </div>
    </div>
</template>
<script>
import { wechatList, setWechat } from "../../service/dataInfo.js"
export default {
    data () {
        return {
            loading: false,
            ruleForm: {},
            rules: {
                groupName: [{ required: true, message: "请填写子账号名称", trigger: "change" }],
            },
        }
    },
    created () {
        if (this.$route.query.gid) {
            this.wechatList(this.$route.query.gid)
        }
    },
    methods: {
        async setWechat() { // 提交设置
            let ruleForm = {...this.ruleForm}
            if (ruleForm.login) {
                ruleForm.login = 1
            } else {
                ruleForm.login = 0
            }
            if (ruleForm.scheduling) {
                ruleForm.scheduling = 1
            } else {
                ruleForm.scheduling = 0
            }
            if (ruleForm.isAnnouncement) {
                ruleForm.isAnnouncement = 1
            } else {
                ruleForm.isAnnouncement = 0
            }
            if (ruleForm.isAssessment == '子账号均值') {
                ruleForm.isAssessment = 1
            } else {
                ruleForm.isAssessment = 0
            }
            if (ruleForm.daily && ruleForm.daily.length) {
                ruleForm.daily = ruleForm.daily.join(',')
            } else {
                ruleForm.daily = ''
            }
            ruleForm.timeOut = 1
            this.loading = true
            let { code } = await setWechat(ruleForm)
            this.loading = false
            if (code == 200) {
                this.$message.success('微信提醒设置已修改成功!')
                this.$router.push('/wechat/wechat-robot')
            }
        },
        deleteDaily (i) {
            let ruleForm = {...this.ruleForm}
            if (ruleForm.daily && ruleForm.daily.length) {
                ruleForm.daily = ruleForm.daily.filter((item, index) => {
                    return i !=index
                })
            }
            this.ruleForm = ruleForm
        },
        addDaily () {
            let ruleForm = {...this.ruleForm}
            if (ruleForm.daily && ruleForm.daily.length) {
                ruleForm.daily.push('')
            }
            this.ruleForm = ruleForm
        },
        async wechatList(gid) {
            this.loading = true
            let { data } = await wechatList({ gid })
            this.loading = false
            if (data && data.length) {
                if (data[0].login == 1) {
                    data[0].login = true
                } else {
                    data[0].login = false
                }
                if (data[0].scheduling == 1) {
                    data[0].scheduling = true
                } else {
                    data[0].scheduling = false
                }
                if (data[0].isAnnouncement == 1) {
                    data[0].isAnnouncement = true
                } else {
                    data[0].isAnnouncement = false
                }
                if (data[0].isAssessment == 1) {
                    data[0].isAssessment = '子账号均值'
                } else {
                    data[0].isAssessment = '超时预警'
                }
                if (data[0].daily) {
                    let daily = data[0].daily.split(',')
                    data[0].daily = daily
                } else {
                    data[0].daily = ['']
                }
                this.ruleForm = data[0]
            } else {
                this.ruleForm = {}
            }
        }
    }
}
</script>
<style lang="less" scoped>
.wechatRobot-container {
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    .el-icon-plus, .el-icon-delete {
        cursor: pointer;
        margin-left: 8px;
    }
    .el-icon-plus {
        color: #1890ff;
        font-weight: 700;
    }
    .wechatRobot-main {
        flex: 1;
        overflow: auto;
    }
    .wechatRobot-bottom {
        height: 68px;
        box-sizing: border-box;
        padding: 16px 32px 0 0;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .title {
        box-sizing: border-box;
        padding: 12px 0 28px;
        height: 68px;
        color: #333;
        font-size: 28px;
        line-height: 28px;
        text-indent: 28px;
        position: relative;
        display: flex;
        align-items: center;
        &::after {
            content: ' ';
            display: block;
            width: 120%;
            position: absolute;
            bottom: 0;
            left: -10%;
            height: 1px;
            background: #ccc;
        }
        .title-text {
            color: #666;
            font-size: 20px;
        }
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
}
</style>